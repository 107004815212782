import { graphql } from 'gatsby'
import React from 'react'

import Footer from '../components/Footer'
import SEO from '../components/header/SEO'
import SiteNav from '../components/header/SiteNav'
import PostCard from '../components/PostCard'
import Wrapper from '../components/Wrapper'
import IndexLayout from '../layouts'
import {
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared'

const Tags = props => {
  const tag = props.pageContext.tag ? props.pageContext.tag : ''
  const { edges } = props.data.allContentfulPost
  const totalCount = edges.length
  // const tagData = props.data.allTagYaml.edges.find(
  //   n => n.node.id.toLowerCase() === tag.toLowerCase(),
  // );
  const tagData = false

  return (
    <IndexLayout>
      {/* <Helmet>
        <html lang={config.lang} />
        <title>
          {tag} - {config.title}
        </title>
        <meta name="description" content={tagData && tagData.node ? tagData.node.description : ''} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${tag} - ${config.title}`} />
        <meta property="og:url" content={config.siteUrl + props.uri} />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${tag} - ${config.title}`} />
        <meta name="twitter:url" content={config.siteUrl + props.uri} />
        {config.twitter && <meta name="twitter:site" content={`@${config.twitter.split('https://twitter.com/')[1]}`} />}
      </Helmet> */}
      <SEO />
      <Wrapper>
        <header
          className={`${tagData && tagData.node.image ? '' : 'no-cover'}`}
          css={[outer, SiteHeader]}
          style={{
            backgroundImage:
              tagData && tagData.node.image ? `url('${tagData.node.image.childImageSharp.fluid.src}')` : '',
          }}
        >
          <div css={inner}>
            <SiteNav isHome={false} />
            <SiteHeaderContent>
              <SiteTitle>{tag}</SiteTitle>
              <SiteDescription>
                {tagData && tagData.node.description ? (
                  tagData.node.description
                ) : (
                  <>
                    A collection of {totalCount > 1 && `${totalCount} posts`}
                    {totalCount === 1 && `1 post`}
                    {totalCount === 0 && `No posts`}
                  </>
                )}
              </SiteDescription>
            </SiteHeaderContent>
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed, PostFeedRaise]}>
              {edges.map(({ node }) => (
                <PostCard key={node.slug} post={node} />
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allContentfulPost(filter: { tags: { eq: $tag } }) {
      edges {
        node {
          title
          slug
          heroImage {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          publishDate
          tags
          body {
            childMarkdownRemark {
              excerpt
              timeToRead
            }
          }
          author {
            name
            image {
              fluid(maxWidth: 90) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`
